<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

const router = useRouter()

const handleError = () => clearError({ redirect: '/' })
function goBack() {
  clearError({})
  router.go(-1)
}

const commonErrors = {
  404: 'The page you are looking for does not exist.',
  500: 'An error occurred on the server. Please try again later.',
  503: 'The service is unavailable. Please try again later.',
  419: 'The page has expired due to inactivity. Please refresh and try again.',
  429: 'Too many requests. Please try again later.',
  401: 'Unauthorized. Please log in to continue.',
  403: 'Forbidden. You do not have permission to access this page.',
} as const

const message = computed(() => commonErrors?.[props.error?.statusCode as keyof typeof commonErrors] || 'An unexpected error occurred.')
</script>

<template>
  <div class="background-squares h-screen w-screen">
    <div class="container mx-auto flex h-screen items-center justify-center">
      <div class="card w-96 bg-neutral text-neutral-content">
        <div class="card-body items-center text-center">
          <h2 class="card-title">
            Error {{ error?.statusCode }}
          </h2>
          <p>
            {{ message }}
          </p>
          <div class="card-actions justify-end">
            <button class="btn btn-primary" @click="goBack">
              Go Back
            </button>
            <button class="btn btn-ghost" @click="handleError">
              Go Home
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
