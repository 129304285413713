import { default as index4jEovxqLGyMeta } from "/app/pages/apps/[slug]/customers/index.vue?macro=true";
import { default as indexPOrXUpzAVtMeta } from "/app/pages/apps/[slug]/features/index.vue?macro=true";
import { default as indexJXvTBTZDnrMeta } from "/app/pages/apps/[slug]/index.vue?macro=true";
import { default as indexOLeLk7W3zpMeta } from "/app/pages/apps/index.vue?macro=true";
import { default as errorT0PrdEAY6vMeta } from "/app/pages/auth/error.vue?macro=true";
import { default as signinFK2lTWFqnvMeta } from "/app/pages/auth/signin.vue?macro=true";
import { default as verify_45requestevDC4PTXAqMeta } from "/app/pages/auth/verify-request.vue?macro=true";
import { default as indextzb25vHoj6Meta } from "/app/pages/dashboard/account/index.vue?macro=true";
import { default as billingmDF0xtQMYaMeta } from "/app/pages/dashboard/billing.vue?macro=true";
import { default as get_45startedMc8HzZCUX5Meta } from "/app/pages/dashboard/get-started.vue?macro=true";
import { default as index8HWYX8hAW5Meta } from "/app/pages/dashboard/integrations/index.vue?macro=true";
import { default as landingLEwXMxyH0OMeta } from "/app/pages/dashboard/landing.vue?macro=true";
import { default as indexMz7ffZfliDMeta } from "/app/pages/dashboard/settings/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as termsy18ZESLqu6Meta } from "/app/pages/terms.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "apps-slug-customers___en",
    path: "/apps/:slug()/customers",
    meta: index4jEovxqLGyMeta || {},
    component: () => import("/app/pages/apps/[slug]/customers/index.vue")
  },
  {
    name: "apps-slug-features___en",
    path: "/apps/:slug()/features",
    meta: indexPOrXUpzAVtMeta || {},
    component: () => import("/app/pages/apps/[slug]/features/index.vue")
  },
  {
    name: "apps-slug___en",
    path: "/apps/:slug()",
    meta: indexJXvTBTZDnrMeta || {},
    component: () => import("/app/pages/apps/[slug]/index.vue")
  },
  {
    name: "apps___en",
    path: "/apps",
    meta: indexOLeLk7W3zpMeta || {},
    component: () => import("/app/pages/apps/index.vue")
  },
  {
    name: "auth-error___en",
    path: "/auth/error",
    meta: errorT0PrdEAY6vMeta || {},
    component: () => import("/app/pages/auth/error.vue")
  },
  {
    name: "auth-signin___en",
    path: "/auth/signin",
    meta: signinFK2lTWFqnvMeta || {},
    component: () => import("/app/pages/auth/signin.vue")
  },
  {
    name: "auth-verify-request___en",
    path: "/auth/verify-request",
    meta: verify_45requestevDC4PTXAqMeta || {},
    component: () => import("/app/pages/auth/verify-request.vue")
  },
  {
    name: "dashboard-account___en",
    path: "/dashboard/account",
    meta: indextzb25vHoj6Meta || {},
    component: () => import("/app/pages/dashboard/account/index.vue")
  },
  {
    name: "dashboard-billing___en",
    path: "/dashboard/billing",
    meta: billingmDF0xtQMYaMeta || {},
    component: () => import("/app/pages/dashboard/billing.vue")
  },
  {
    name: "dashboard-get-started___en",
    path: "/dashboard/get-started",
    meta: get_45startedMc8HzZCUX5Meta || {},
    component: () => import("/app/pages/dashboard/get-started.vue")
  },
  {
    name: "dashboard-integrations___en",
    path: "/dashboard/integrations",
    meta: index8HWYX8hAW5Meta || {},
    component: () => import("/app/pages/dashboard/integrations/index.vue")
  },
  {
    name: "dashboard-landing___en",
    path: "/dashboard/landing",
    meta: landingLEwXMxyH0OMeta || {},
    component: () => import("/app/pages/dashboard/landing.vue")
  },
  {
    name: "dashboard-settings___en",
    path: "/dashboard/settings",
    meta: indexMz7ffZfliDMeta || {},
    component: () => import("/app/pages/dashboard/settings/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "privacy___en",
    path: "/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    component: () => import("/app/pages/privacy.vue")
  },
  {
    name: "terms___en",
    path: "/terms",
    meta: termsy18ZESLqu6Meta || {},
    component: () => import("/app/pages/terms.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]