<script lang="ts" setup>
import { UseColorMode } from '@vueuse/components'
import { Toaster } from 'vue-sonner'

const colorMode = useColorMode()
</script>

<template>
  <UseColorMode>
    <Toaster
      rich-colors
      :theme="colorMode === 'dark' ? 'dark' : 'light'"
    />
    <NuxtPage />
  </UseColorMode>
</template>
