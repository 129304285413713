export default defineNuxtRouteMiddleware(() => {
  const config = useRuntimeConfig()

  if (!config.public.featrack.enabled) {
    return
  }

  const { data } = useAuth()

  const { user } = data.value || {}

  if (!user) {
    return
  }

  const { $featrack } = useNuxtApp()

  $featrack.customers.identify(user.id)
})
