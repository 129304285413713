import { FT } from '@jeepayjipex/featrack-sdk/js'

declare module '#app' {
  interface NuxtApp {
    $featrack: ReturnType<typeof FT>
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $featrack: ReturnType<typeof FT>
  }
}

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()

  const featrack = FT(runtimeConfig.public.featrack.apiKey, runtimeConfig.public.featrack.appSlug, {
    ftApiUrl: runtimeConfig.public.featrack.url,
    errorMode: 'warn'
  })

  return {
    provide: {
      featrack
    }
  }
})
