import { isBefore } from 'date-fns'

export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig()
  if (config.public.in_beta || to.meta.unPaidAccess) {
    return
  }

  const { data } = useAuth()
  const { user } = data.value || {}

  if (!user) {
    return navigateTo('/auth/signin')
  }
  const isInTrialPeriod = user.trialEnd && isBefore(new Date(), user.trialEnd)
  if (!user.subscriptionId && !isInTrialPeriod) {
    return navigateTo('/dashboard/billing')
  }
})
